import React from "react";
import { Switch, Route } from "react-router-dom";
import PluginRoutes from "./pluginApps/Routes";
import AddPluginPage from "./pluginApps/addPlugin/Page";

import BillingRedirect from "../../billing/BillingRedirect";
import SupportRedirect from "../../tickets/common/components/supportRedirect";

const InvitationWaitingPage = React.lazy(() =>
  import(
    "../../organization/pages/organizationProjects/containers/InvitationWaiting"
  )
);
const LogoutPage = React.lazy(() => import("./logout/Page"));
const PostLogoutPage = React.lazy(() => import("./postLogout/Page"));
const OrganizationsRoutes = React.lazy(() =>
  import("../../organization/pages/Routes")
);
const LegacyBillingRoutes = React.lazy(() => import("../../billing/Routes"));
const ProjectsRoutes = React.lazy(() =>
  import("../../organization/pages/projects/Routes")
);

const Page = React.lazy(() => import("./Page"));

const UsersRoutes = React.lazy(() => import("./users/Routes"));

const Routes = () => (
  <Page>
    <Switch>
      <Route path="/support">
        <SupportRedirect />
      </Route>
      <Route path={`/-/users`}>
        <UsersRoutes />
      </Route>
      <Route path={`/-/app/:appId`}>
        <PluginRoutes />
      </Route>
      <Route path={`/-/add-plugin`}>
        <AddPluginPage />
      </Route>
      <Route path="/-/logout">
        <LogoutPage />
      </Route>
      <Route path="/-/post-logout">
        <PostLogoutPage />
      </Route>
      <Route path="/-/billing">
        <LegacyBillingRoutes />
      </Route>
      <Route path="/-/organizations/:id/wait">
        <InvitationWaitingPage />
      </Route>
      <Route path="/billing">
        <BillingRedirect />
      </Route>
      <Route path="/:organizationId">
        <OrganizationsRoutes />
      </Route>
      <Route path="/">
        <ProjectsRoutes />
      </Route>
    </Switch>
  </Page>
);

export default Routes;
